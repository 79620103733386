<template>
  <v-content>
    <v-container>
      <v-card style="text-align:center">
        <v-card-title></v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              {{message}}
              <template v-for="(e,idx) in errors">
                <div :key="idx">
                  {{e}}
                  <br />
                  <br />
                </div>
              </template>
            </v-flex>
          </v-layout>
          <v-progress-linear color="primary" indeterminate v-if="isRemoving"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-container>
  </v-content>
</template>
<script>
import { authComputed } from '@state/helpers'
export default {
  page: {
    title: 'Wrong Email',
  },
  data() {
    return {
      message: '',
      isRemoving: true,
      errors: [],
    }
  },
  created() {
    let email = this.$route.query.email
    this.message = `Removing account for ${email}`
    this.removeUserIdentity(email)
    this.getIdentityUrl()
  },
  computed: {
    ...authComputed,
  },
  methods: {
    removeUserIdentity(email) {
      this.errors = []
      let token = this.$route.query.token
      const self = this
      if (email && token) {
        axios
          .get(
            `${this.identityUrl}/api/Auth/WrongEmail?email=${encodeURIComponent(
              email
            )}&token=${token}`
          )
          .then((response) => {
            this.removeUserApplication(email)
          })
          .catch((e) => {
            this.isRemoving = false
            this.message = `There was an error removing account for ${email} -`
            let err = e.response.data
            if (JSON.stringify(err).substring(0, 1) != '{') {
              self.errors.push(err)
            } else {
              Object.keys(err).forEach((val) => {
                self.errors.push(err[val][0])
              })
            }
          })
      } else {
        this.isRemoving = false
        this.message = `Unable to remove account`
      }
    },
    removeUserApplication(email) {
      axios
        .get(`/Register/DeleteAccount/${encodeURIComponent(email)}`)
        .then((response) => {
          this.isRemoving = false
          this.message = `Account for ${email} has been removed`
        })
        .catch((e) => {
          this.isRemoving = false
          this.message = `There was an error removing account for ${email} -`
          let err = e.response.data
          if (JSON.stringify(err).substring(0, 1) != '{') {
            self.errors.push(err)
          } else {
            Object.keys(err).forEach((val) => {
              self.errors.push(err[val][0])
            })
          }
        })
    },
  },
}
</script>

<style>
</style>
